.sortSelector {
	label {
		display: flex;
		justify-content: center;
		margin-right: 8px;
	}
	
	button {
    text-overflow: ellipsis;
    width: 200px;
	}
}
